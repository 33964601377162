<template>
  <div class="relation">
    <van-nav-bar fixed title="中美厚仁关系说明" left-text="返回" class="navBar" left-arrow @click-left="onClickLeft"> </van-nav-bar>
    <div class="html-wrap">
      <div v-html="conObj.page_header"></div>
      <div v-html="conObj.page_body"></div>
      <div v-html="conObj.page_footer"></div>
    </div>
  </div>
</template>

<script>
import { backInitMixin } from '@/utils/mixin';
export default {
  components: {},
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      conObj: {}
    };
  },
  computed: {},
  created() {
    this.getData();
  },
  mounted() {},
  watch: {},
  methods: {
    getData() {
      this.$api['searchArea/relation']().then(res => {
        if (res.message) {
          this.conObj = res.message;
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.relation {
  padding-top: 46px;
  .html-wrap {
    padding: 20px 15px;
  }
}
</style>
